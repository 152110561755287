<template>
  <div>
    <div class="h-35 bg-white space-y-2 px-8 py-6">
      <div class="flex justify-between items-end">
        <div class="space-y-2">
          <p class="text-sm">Organizations</p>
          <h2 class="font-medium">All Organizations</h2>
          <p class="text-sm text-bb-mid-grey">A list of all the organizations you manage</p>
        </div>
        <div class="flex space-x-6 items-center bg-white">
          <div class="w-80">
            <div>
              <SearchBar
                ref="siteSearch"
                placeholder="Search Organization..."
                class="active"
                :icon-size="16"
                @filter-term-changed="filterTerm"
              />
            </div>
          </div>
          <div v-if="!standardUser">
            <MergeButtonRound
              :disabled="isLoading"
              button-type="primary"
              class="w-48"
              @click="createNewOrganization"
            >
              <p class="flex items-center">
                <ic-plus
                  :size="16"
                  class="pr-1"
                />
                <span>Add Account</span>
              </p>
            </MergeButtonRound>
          </div>
        </div>
      </div>
    </div>

    <div class="overflow-y-auto base-scrollbar">
      <bb-table
        class="mx-8 my-6 border rounded-md"
        :headers="headers"
        :data="filteredOrganizationList"
        :is-loading="isLoading"
        :scroll-y="true"
        :sticky-header="true"
        :default-sorting-order="defaultSortingOrder"
        max-table-height="calc(100vh - 400px)"
      >
        <template #rows="{ tableData }">
          <organization-rows
            :data="tableData"
            @select-row="selectRow"
          />
        </template>
      </bb-table>
    </div>

    <!--MODAL COMPONENTS-->
    <bb-base-modal
      v-if="formValues"
      :width="size.width"
      :height="size.height"
      :form-values="formValues"
      :is-modal-loading="isModalLoading"
      @close="closeModal"
    >
      <template #container="{ form, loadingStatus }">
        <edit-organization-name-modal-form
          v-if="form.modal === 'edit-organization-name'"
          :selected-organization="form.data"
          :is-loading="loadingStatus"
          @close="closeModal"
          @save="saveOrganizationName"
        />
        <assign-user-modal-form
          v-if="form.modal === 'assign-to-user'"
          :is-loading="loadingStatus"
          :default-organization="form.data"
          :options="organizationOptions"
          :existing-user="existingUser"
          :user-options="userOptions"
          @close="closeModal"
          @save="assignAndInviteUser"
        />
        <remove-item-modal
          v-if="form.modal === 'remove-item'"
          :is-loading="loadingStatus"
          @close="closeModal"
          @remove="removeOrganization"
        >
          <div>
            <h4 class="h4 pr-8 mt-10">
              Are you sure you want to REMOVE the Organization
              <span class="font-bold">{{ form.data.organization }}</span
              >?
            </h4>
          </div>
          <div class="mt-4">
            <p>This action cannot be undone.</p>
          </div>
        </remove-item-modal>
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import BbTable from '@/components/table/BbTable'
import EditOrganizationNameModalForm from '@/components/modals/forms/EditOrganizationNameModalForm'
import AssignUserModalForm from '@/components/modals/forms/AssignUserModalForm.vue'
import OrganizationRows from '@/components/table/table_rows/OrganizationRows'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import { mapActions, mapGetters, mapState } from 'vuex'
import Toast from '@/components/shared/Toast'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal'
import { ACTIONS, MODULE_NAME as USER_MODULE } from '@/store/modules/user/user'
import SearchBar from '@/components/layout/Navbar/OrganizationAndSite/SearchBar.vue'
import RemoveItemModal from '@/components/modals/forms/RemoveItemModal.vue'

export default {
  name: 'OrganizationView',
  components: {
    BbTable,
    EditOrganizationNameModalForm,
    AssignUserModalForm,
    OrganizationRows,
    BbBaseModal,
    MergeButtonRound,
    SearchBar,
    RemoveItemModal,
  },
  data() {
    return {
      isLoading: false,
      isModalLoading: false,
      formValues: null,
      size: null,
      existingUser: null,
      query: null,
      defaultSortingOrder: { order: 'DESCENDING', column: 'organization' },
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('organization', ['userOrganization']),
    ...mapGetters({
      userOrganizationRows: 'organization/userOrganizationRows',
      userOptions: `${USER_MODULE}/userOptions`,
      standardUser: `auth/isStandardUser`,
    }),
    headers() {
      return [
        {
          value: 'organization',
          label: 'Orgs',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'status',
          label: 'Status',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'users',
          label: 'Users',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'sites',
          label: 'Accounts',
          sortable: false,
          sorting: null,
          position: 'right',
        },
        // Only add the 'actions' column if the user is not a standard user
        ...(!this.standardUser
          ? [
              {
                value: 'actions',
                label: 'Actions',
                sortable: false,
                sorting: null,
                position: 'right',
              },
            ]
          : []),
      ]
    },
    organizationOptions() {
      return this.userOrganization.map(item => {
        return {
          label: item.name,
          value: item.id,
        }
      })
    },
    filteredOrganizationList() {
      if (!this.query) {
        return this.userOrganizationRows
      }
      return this.userOrganizationRows.filter(
        organization =>
          organization.organization.toLowerCase().includes(this.query.toLowerCase()) ||
          organization.sites.some(site => site.name.toLowerCase().includes(this.query.toLowerCase())),
      )
    },
  },
  async mounted() {
    this.isLoading = true
    await this.getAllOrganizations()
    if (this.userOptions.length === 0 && !this.standardUser) await this.getAllUsers()
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      loadUserOrganizationList: 'organization/loadUserOrganizationList',
      loadUsers: `${USER_MODULE}/${ACTIONS.LOAD_USERS}`,
    }),
    async getAllOrganizations() {
      try {
        await this.loadUserOrganizationList(this.user.id)
      } catch (error) {
        console.log(error)
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch the organizations',
            type: 'error',
          },
        })
        return [] // return blank array
      }
    },
    closeModal() {
      this.formValues = null
    },
    selectRow(selectedItem) {
      // get the selected row
      this.formValues = selectedItem

      switch (this.formValues.modal) {
        case 'edit-organization-name':
          this.size = { width: '464px', height: '284px' }
          break
        case 'assign-to-user':
          this.size = { width: '800px', height: '662px' }
          break
        case 'remove-item':
          if (this.formValues.data.sites.length) {
            this.formValues = null
            this.$toast.warning({
              component: Toast,
              props: {
                title: 'Warning',
                message: 'To remove this Organization you need to remove all accounts within this Organization first.',
                type: 'warning',
              },
            })
          } else {
            this.size = { width: '560px', height: '280px' }
          }
          break
      }
    },
    async saveOrganizationName(organizationName) {
      if (!organizationName) return
      try {
        this.isModalLoading = true
        const payload = { name: organizationName, id: this.formValues.data.id }
        await this.$http.patch(`common/organization/${payload.id}`, payload)

        // fetch the organizations of user
        await this.getAllOrganizations()
      } catch (error) {
        console.log(error)
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to update the organization name',
            type: 'error',
          },
        })
      } finally {
        this.isModalLoading = false
        this.closeModal()
      }
    },
    async inviteUser(email, organizations) {
      try {
        const payload = {
          email: email,
          role_slug: 'manager', // default: manager
          organization_ids: organizations.map(item => item.value),
        }
        await this.$http.post('common/user/invite', payload)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Successfully sent an invitation.',
            type: 'success',
          },
        })
      } catch (error) {
        if (error.response.data === 'User with this email already exists' && error.response.status === 409) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: error.response.data,
              type: 'error',
            },
          })
        }
      }
    },
    async assignUser(users, organizations) {
      try {
        const payload = {
          user_ids: users.map(user => user.id),
          organization_ids: organizations.map(org => org.value),
        }
        await this.$http.post('/common/user/assign-users-to-organizations', payload)
        await this.loadUserOrganizationList(this.user.id)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Successfully assign user/s to organization/s.',
            type: 'success',
          },
        })
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: error.response.data,
            type: 'error',
          },
        })
      }
    },
    async assignAndInviteUser(invitationInfo) {
      if (!invitationInfo) return
      try {
        this.isModalLoading = true
        await this.assignUser(invitationInfo.users, invitationInfo.organizations)

        if (invitationInfo.email) {
          await this.inviteUser(invitationInfo.email, invitationInfo.organizations)
        }
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to assign and invite the user',
            type: 'error',
          },
        })
      } finally {
        this.isModalLoading = false
        this.closeModal()
      }
    },
    async removeOrganization() {
      try {
        this.isModalLoading = true
        await this.$http.delete(`common/organization/${this.formValues.data.id}`)
        this.closeModal()
        this.isLoading = true
        await this.getAllOrganizations()
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to remove the organization. Please try again later',
            type: 'error',
          },
        })
      } finally {
        this.isModalLoading = false
        this.isLoading = false
      }
    },
    createNewOrganization() {
      this.$router.push({
        name: 'onboarding-wizard',
        query: {
          desiredState: 'SETUP_CONNECTIONS',
        },
      })
    },
    async getAllUsers() {
      try {
        await this.loadUsers()
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch users',
            type: 'error',
          },
        })
      }
    },
    filterTerm(term) {
      this.query = term
    },
  },
}
</script>

<style scoped lang="scss"></style>
