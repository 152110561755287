<template>
  <tbody class="divide-y divide-bb-neutral-50">
    <tr
      v-for="(item, index) in data"
      :key="index"
      class="h-18 text-sm cursor-pointer"
      :class="hoveredRow === index ? 'bg-bb-background-purple-0' : ''"
      @click.stop="redirect(item.id)"
      @mouseenter="hoveredRow = index"
      @mouseleave="hoveredRow = null"
    >
      <td
        class="px-4 whitespace-no-wrap sticky left-0"
        :class="hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white'"
      >
        {{ item.organization }}
      </td>
      <td class="px-4">
        <div class="flex gap-2 items-center">
          <div
            class="h-3 w-3 rounded-full"
            :class="item.isActive ? 'bg-success' : 'bg-bb-error'"
          />
          <p>
            {{ item.isActive ? 'Active' : 'Inactive' }}
          </p>
        </div>
      </td>
      <td class="px-4">
        <p v-if="item.managers">
          {{ item.managers }}
        </p>
        <p v-else>-</p>
      </td>
      <td class="text-right px-4">
        <p>{{ item.siteCount }}</p>
      </td>
      <td
        v-if="!isStandardUser"
        class="px-4"
      >
        <div class="flex justify-end">
          <kebab
            :item-data="item"
            :menu="menu"
            menu-position="left"
            @select-action="selectAction"
          />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import Kebab from '@/components/icon/brightbid/kebab'
import { mapGetters } from 'vuex'

export default {
  name: 'OrganizationRows',
  components: {
    Kebab,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      menu: [
        { label: 'Assign to User', value: 'assign-to-user' },
        { label: 'Edit Organization', value: 'edit-organization-name' },
        { label: 'Remove Organization', value: 'remove-item' },
      ],
      hoveredRow: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['isStandardUser']),
  },
  methods: {
    selectAction(action, item) {
      this.$emit('select-row', { modal: action.value, data: item })
    },
    redirect(id) {
      this.$router.push({ name: 'settings:organization:sites', params: { id: id } })
    },
  },
}
</script>

<style scoped></style>
